'use client';

const Error = ({ error, reset }) => {
    return (
      <section className="py-24">
          <div className="container flex flex-col">
              <h2 className="mb-4 text-red-400">{error.response?.data ? error.response.data.message : error.message}</h2>
              {/* <button className="rounded bg-white px-3 text-sm text-black disabled:opacity-50" onClick={() => reset()}>Contact the owner of the site</button> */}
              <button className="rounded bg-white px-3 text-sm text-black disabled:opacity-50" onClick={() => reset()}>Snap 😫😫😫, Network error. Try again</button>
          </div>
      </section>
    )
  }
  
export default Error